<template>
  <div class="edp-backend">
    <edp-backend-user />

    <section class="edp-backend-middle">
      <edp-backend-menu />

      <!-- content -->
      <section class="edp-backend-content">
        <div class="edp-backend-content-out">
          <mainTitle> </mainTitle>
          <section class="edp-backend-conditionSearch">
            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">
                {{ $t("edpBackendEntry.ftableTite2") }}
              </div>

              <div class="edp-backend-conditionSearch-content">
                <el-input
                  max="100"
                  placeholder="请输入"
                  v-model="query.funcCode"
                  clearable
                />
              </div>
            </div>
            <div class="edp-backend-conditionSearch-square">
              <div class="edp-backend-conditionSearch-label">
                {{ $t("edpBackendEntry.ftableTite1") }}
              </div>

              <div class="edp-backend-conditionSearch-content">
                <el-input
                  max="100"
                  placeholder="请输入"
                  v-model="query.funcName"
                  clearable
                />
              </div>
            </div>

            <div
              class="edp-backend-conditionSearch-square conditionSearch-square-last"
            >
              <el-button size="small" @click="onclear">{{
                $t("edpBackendCommon.clear")
              }}</el-button>
              <el-button size="small" type="primary" @click="getList(1)">{{
                $t("edpBackendCommon.search")
              }}</el-button>
            </div>
          </section>
          <section class="edp-backend-common-table">
            <div class="table-headControlLine">
              <div>
                <el-button type="primary" size="small" @click="onShow(1)">
                  {{ $t("edpBackendCommon.add") }}</el-button
                >
              </div>
            </div>

            <el-table
              stripe
              :data="pageList"
              style="width: 100%"
              v-loading="loading"
            >
              <el-table-column
                fixed
                prop="funcName"
                :label="$t('edpBackendEntry.ftableTite1')"
                width="200"
              >
              </el-table-column>

              <el-table-column
                prop="funcCode"
                :label="$t('edpBackendEntry.ftableTite2')"
              >
              </el-table-column>

              <el-table-column
                prop="funcReqUrl"
                :label="$t('edpBackendEntry.tableTite4')"
              ></el-table-column>

              <el-table-column
                prop="funcSorted"
                :label="$t('edpBackendEntry.formTit4')"
              ></el-table-column>

              <el-table-column
                prop="createTime"
                :label="$t('edpBackendEntry.tableTite6')"
              ></el-table-column>

              <el-table-column
                :label="$t('edpBackendCommon.operate')"
                fixed="right"
                width="110"
              >
                <template slot-scope="scope">
                  <el-button type="text" @click="onShow(2, scope.row)">
                    {{ $t("edpBackendCommon.edit") }}</el-button
                  >
                  <el-button type="text" @click="deleteRow(scope.row)">
                    {{ $t("edpBackendCommon.delete") }}</el-button
                  >
                </template>
              </el-table-column>
            </el-table>

            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="pageTotal"
            >
            </el-pagination>
          </section>
        </div>
      </section>
      <!-- content end -->
    </section>
    <el-dialog
      :title="dialogTitle"
      width="30%"
      :visible.sync="editShow"
      :before-close="onClose"
    >
      <el-form :model="form" label-width="150px" ref="funForm">
        <el-form-item :label="$t('edpBackendEntry.ftableTite2')">
          <el-input v-model="form.funcCode" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('edpBackendEntry.ftableTite1')"
          :rules="[{ required: true, message: '不能为空' }]"
        >
          <el-input v-model="form.funcName" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="$t('edpBackendEntry.ftableTite3')" prop="name">
          <form-select-tree
            style="width: 100%"
            :isRadio="true"
            :data="menus"
            label="id"
            :placeholder="$t('edpBackendCommon.placeholder')"
            :value.sync="form.menuId"
            :selVal="[form.menuId]"
          ></form-select-tree>
        </el-form-item>
        <el-form-item :label="$t('edpBackendEntry.tableTite4')">
          <el-input v-model="form.funcReqUrl" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="$t('edpBackendEntry.ftableTite4')">
          <el-select
            v-model="form.funcReqMethod"
            :placeholder="$t('edpBackendCommon.placeholder')"
            clearable
          >
            <el-option
              v-for="(item, i) in methods"
              :key="i"
              :label="item.value"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('edpBackendEntry.formTit4')">
          <el-input
            v-model.number="form.funcSorted"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="editShow = false">{{
          $t("edpBackendCommon.canale")
        }}</el-button>
        <el-button type="primary" @click="onSave('funForm')">{{
          $t("edpBackendCommon.save")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import FormSelectTree from "@/src/components/common/FormSelectTree";
export default {
  name: "backendFunction",
  components: {
    FormSelectTree,
  },
  data() {
    return {
      query: {
        funcCode: "",
        funcName: "",
      },
      pageList: [],
      currentPage: 1,
      pageSize: 10,
      pageTotal: 0,
      loading: false,
      editShow: false,
      dialogTitle: this.$t("edpBackendCommon.add"),
      form: {
        id: null,
        menuId: null,
        funcCode: "",
        funcName: "",
        funcReqMethod: "",
        funcReqUrl: "",
        funcSorted: 0,
      },
      menus: [],
      methods: [
        { name: "GET", value: "GET" },
        { name: "POST", value: "POST" },
        { name: "DELETE", value: "DELETE" },
        { name: "PUT", value: "PUT" },
        { name: "PATCH", value: "PATCH" },
      ],
    };
  },
  mounted() {
    this.getList();
    this.getMenu();
  },
  methods: {
    ...mapActions({
      functionList: "user/functionList",
      addFunction: "user/addFunction",
      delFunction: "user/delFunction",
      editFunction: "user/editFunction",
      menuList: "backendMenu/menuList",
    }),
    async getList(val) {
      if (val) {
        this.currentPage = val;
      }
      let params = {
        current: this.currentPage,
        size: this.pageSize,
        ...this.query,
      };
      let res = await this.functionList(params);
      console.log(res);
      this.pageList = res.data.records;
      this.currentPage = res.data.current;
      this.pageTotal = res.data.total;
    },
    async getMenu() {
      let params = { current: 1, size: 100 };
      let res = await this.menuList(params);
      console.log(res);
      this.menus = res.data.data;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    onclear() {
      this.query = {
        funcCode: "",
        funcName: "",
      };
    },
    onClose(done) {
      for (var key in this.form) {
        this.form[key] = key === "funcSorted" ? 0 : "";
      }
      done();
    },
    onShow(val, data) {
      if (val == 1) {
        this.dialogTitle = this.$t("edpBackendCommon.add");
      } else {
        console.log(data);
        this.dialogTitle = this.$t("edpBackendCommon.edit");
        this.form = JSON.parse(JSON.stringify(data));
      }
      this.editShow = true;
    },

    async onSave(formName) {
      let res = {};
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (Array.isArray(this.form.menuId)) {
            this.form.menuId = this.form.menuId[0];
          }
          if (this.form.id) {
            res = await this.editFunction(this.form);
          } else {
            res = await this.addFunction(this.form);
          }

          if (res.success) {
            this.editShow = false;
            for (var key in this.form) {
              this.form[key] = key === "funcSorted" ? 0 : "";
            }
            this.getList();
            this.$message({
              type: "success",
              message: this.$t("edpBackendCommon.actionSuccess"),
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async deleteRow(val) {
      this.$confirm("入口删除后，用户将无法看到入口，请确认是否删除？", "删除")
        .then(async (_) => {
          let res = await this.delFunction(val);
          if (res.success) {
            this.getList();
            this.$message({
              type: "success",
              message: this.$t("edpBackendCommon.actionSuccess"),
            });
          }
        })
        .catch((_) => {});
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";
</style>
