<template>
  <div class="Form-select-tree">
    <el-popover
      :visible-arrow="defaluttrue"
      class="attrelcconformitem"
      :class="{ active: filterVisible.channel }"
      placement="bottom"
      trigger="click"
      v-model="filterVisible.channel"
    >
      <div class="attrelccfitem-ctrl" slot="reference">
        <span class="placehold" v-if="!channel.length">{{ placeholder }}</span>
        <p class="value-wrap" v-else>
          <span class="val" v-for="(item, index) in channel" :key="index">{{
            item.menuCnName
          }}</span>
        </p>
        <i class="el-icon-arrow-down"></i>
      </div>
      <div class="relcconformitem-select">
        <!--<preparation-label-item-->
        <!--:data="data"-->
        <!--:selectedValue.sync="filterVal.channel"-->
        <!--:showbol="defaluttrue"-->
        <!--name="channel"-->
        <!--label="itemId"-->
        <!--:checkposition="defaluttrue"-->
        <!--@change="onChange"-->
        <!--:treecheck="defaluttrue"-->
        <!--&gt;</preparation-label-item>-->
        <el-tree
          v-show="isRadio"
          :data="data"
          show-checkbox
          :node-key="label"
          ref="treeForm"
          :default-expanded-keys="filterVal.channel"
          :default-checked-keys="filterVal.channel"
          @check-change="handleNodeClick"
          @check="check"
          :check-strictly="defaluttrue"
          :props="defaultProps"
        ></el-tree>
        <el-tree
          v-show="!isRadio"
          :data="data"
          show-checkbox
          :node-key="label"
          :default-expanded-keys="filterVal.channel"
          :default-checked-keys="filterVal.channel"
          @check="check"
          :check-strictly="false"
          :props="defaultProps"
        ></el-tree>
      </div>
    </el-popover>
  </div>
</template>

<script>
export default {
  name: "formSelectTree",
  props: ["data", "value", "label", "placeholder", "isRadio", "selVal"],
  watch: {
    value(val) {
      // this.filterVal.channel = [...val];
      if (this.isRadio) {
        // this.checkitemId(this.data, this.value);
      }
    },
    // selVal(val) {
    //   this.filterVal.channel = val;
    // }
  },
  data() {
    return {
      checkedId: "",
      defaluttrue: true,
      defalutfalse: true,
      channel: [],
      filterVisible: {
        channel: false,
      },
      filterData: {
        channel: [],
      },
      filterVal: {
        channel: [],
      },
      defaultProps: {
        children: "children",
        label: "menuCnName",
        disadled: null,
      },
    };
  },
  mounted() {
    setTimeout(
      () => (this.channel = this.$refs.treeForm.getCheckedNodes()),
      500
    );

    console.log(this.isRadio, "--------");
    this.filterVal.channel = this.selVal;
  },
  methods: {
    checkitemId(data, val) {
      data.map((key) => {
        val.map((item) => {
          if (key.id == item) {
            this.channel = [key];
          }
        });
        if ("children" in key) {
          if (key.children != null && key.children.length > 0) {
            this.checkitemId(key.children, val);
          }
        }
      });
    },
    check(checkedNodes, checkedKeys) {
      this.channel = [];
      this.channel = checkedKeys.checkedNodes;
      console.log(this.channel, checkedNodes, checkedKeys, "-------");

      this.$emit("update:value", checkedKeys.checkedKeys);
    },
    // 单选判断
    handleNodeClick(data, checked) {
      if (checked === true) {
        this.checkedId = data[this.label];
        this.$refs.treeForm.setCheckedKeys([data[this.label]]);
      } else {
        if (this.checkedId == data.customerCode) {
          this.$refs.treeForm.setCheckedKeys([data[this.label]]);
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable";
.attrelcconformitem {
  display: flex;
  align-items: center;
  // padding: toRem(2) toRem(8);
  // height: 34px;
  padding: 0 20px 0 10px;
  border: 1px solid rgb(220, 223, 230);
  border-radius: 4px;
  .el-popover__reference-wrapper,
  .Form-select-tree {
    width: 100%;
  }

  .attrelccfitem-ctrl {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    position: relative;
    .placehold {
      color: rgba(0, 0, 0, 0.25);
      margin-right: auto;
      display: inline-block;
    }
    .el-icon-arrow-down {
      position: absolute;
      right: -16px;
      top: 50%;
      margin-top: -10px;
    }
    .value-wrap {
      width: 100%;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .val + .val {
      margin-left: toRem(6);
    }
  }
  &.active {
    border-color: none;
  }
}
</style>
<style>
.relcconformitem-select {
  max-height: 500px;
  overflow-y: auto;
}
.el-tree-node__expand-icon {
  font-weight: 900;
  color: #002f6c;
}
</style>
